



























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import { Utils } from '@/app/infrastructures/misc'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import IllutrationView from '@/app/ui/assets/eye.vue'
import IllutrationCancel from '@/app/ui/assets/close_line.vue'
import IllutrationEdit from '@/app/ui/assets/edit_icon.vue'
import controller from '@/app/ui/controllers/UpdateAppVersionController'
import UpdateAppVersionDropdown from '@/app/ui/views/UpdateAppVersion/components/UpdateAppVersionDropdown/index.vue'
import UpdateAppVersionInput from '@/app/ui/views/UpdateAppVersion/components/UpdateAppVersionInput/index.vue'
import * as constantsData from '@/app/infrastructures/misc/Constants/updateAppVersion'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type'
import { EnumUpdateAppVersionStatus } from '@/app/infrastructures/misc/Constants/updateAppVersion'
import Badge from '@/app/ui/components/Badge/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'

@Component({
  components: {
    EmptyState,
    Badge,
    Button,
    AddCircle,
    DataTableV2,
    LoadingOverlay,
    PaginationNav,
    Modal,
    ModalAction,
    IllutrationCancel,
    IllutrationView,
    IllutrationEdit,
    UpdateAppVersionDropdown,
    UpdateAppVersionInput,
  },
})
export default class UpdateAppVersionList extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  constantsData = constantsData

  showModalConfirm = false
  showModalSuccess = false
  cancelID = -1
  cacheSearchKeyword = ''
  searchKeyword = ''
  emptyStateDesc = 'Update app version data will appear on this page'
  queryParams: {
    page: number
    perPage: number
    filterBy: DropDownLabelValue<string> | undefined
    sortBy: DropDownLabelValue<string> | undefined
    keyword: string
  } = {
    page: 1,
    perPage: 10,
    filterBy: undefined,
    sortBy: undefined,
    keyword: '',
  }

  modalActionData = {
    title: '',
    description: '',
    textPrimary: '',
    textSecondary: '',
  }

  dataTableHeaders: HeaderObject[] = [
    this.headerCellMapper('Publish Date', '', ''),
    this.headerCellMapper('Update Detail', '20%', ''),
    this.headerCellMapper('Force', '', ''),
    this.headerCellMapper('Recommend', '', ''),
    this.headerCellMapper('Status', '', ''),
    this.headerCellMapper('Action', '15%', 'center'),
  ]

  created(): void {
    const queries = this.$route.query as Record<string, never>
    this.queryParams = {
      page: queries.page || 1,
      perPage: queries.perPage || 10,
      sortBy: constantsData.sortOptions.find(
        val => val.value === queries.sortBy
      ),
      filterBy: constantsData.filterOptions.find(
        val => val.value === queries.filterBy
      ),
      keyword: queries.keyword,
    }
    this.searchKeyword = queries.keyword
    this.cacheSearchKeyword = queries.keyword

    this.fetchList(false)
  }

  get errorDescEmptyState(): string {
    const queries = this.$route.query as Record<string, never>
    if (
      (queries.keyword !== '' && queries.keyword) ||
      (queries.filterBy !== '' && queries.filterBy) ||
      (queries.sortBy !== '' && queries.sortBy)
    ) {
      return `There is no update app schedule with the selected keyword or filter
         <br/> Please try another keyword or filter`
    }

    return this.emptyStateDesc
  }

  get listData(): Array<DataObject[]> {
    const data: Array<DataObject[]> = []
    for (const upgradeVersion of controller.listData) {
      data.push([
        {
          value: Utils.formatDate(
            upgradeVersion.publishDateTime,
            'DD MMMM YYYY'
          ),
        },
        {
          value: `${upgradeVersion.os.toUpperCase()}, ${
            upgradeVersion.versionValidation
          }`,
          customStyle: {
            maxWidth: '20%',
            minWidth: '20%',
          },
        },
        {
          value:
            upgradeVersion.forceVersion !== '1.0.0'
              ? upgradeVersion.forceVersion
              : '-',
        },
        {
          value:
            upgradeVersion.recommendVersion !== '1.0.0'
              ? upgradeVersion.recommendVersion
              : '-',
        },
        {
          value: upgradeVersion.publishDateTime,
        },
        {
          id: upgradeVersion.id,
          value: upgradeVersion.publishDateTime,
          customStyle: {
            maxWidth: '15%',
            minWidth: '15%',
            display: 'flex',
            justifyContent: 'center',
          },
        },
      ])
    }
    return data
  }

  private headerCellMapper(
    title: string,
    colWidth: string,
    textAlign: string
  ): HeaderObject {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        textAlign: textAlign,
      },
    }
  }

  getStatus(
    indexData: number
  ): { typeBadge: string; status: EnumUpdateAppVersionStatus } {
    const currentData = controller.listData[indexData]
    currentData.status = Utils.toCapitalize(currentData.status)

    if (currentData.status === EnumUpdateAppVersionStatus.CANCELLED) {
      return {
        typeBadge: 'error-1',
        status: EnumUpdateAppVersionStatus.CANCELLED,
      }
    }

    if (currentData.status === EnumUpdateAppVersionStatus.PENDING) {
      return {
        typeBadge: 'warning',
        status: EnumUpdateAppVersionStatus.PENDING,
      }
    }
    return {
      typeBadge: 'success',
      status: EnumUpdateAppVersionStatus.PUBLISHED,
    }
  }

  get onQueryParams(): constantsData.paramsInterface {
    return {
      filterBy: this.queryParams.filterBy?.value || '',
      sortBy: this.queryParams.sortBy?.value || '',
      keyword: this.cacheSearchKeyword,
      page: this.queryParams.page,
      perPage: this.queryParams.perPage,
    }
  }

  onKeywordInput(): void {
    this.cacheSearchKeyword = this.searchKeyword
    Utils.onParamsChanged('keyword', this.searchKeyword)
    this.fetchList(true)
  }

  onFilterBy(): void {
    Utils.onParamsChanged('filterBy', this.onQueryParams.filterBy)
    this.fetchList(true)
  }

  onSortBy(): void {
    Utils.onParamsChanged('sortBy', this.onQueryParams.sortBy)
    this.fetchList(true)
  }

  paginationOnEnter(): void {
    Utils.onParamsChanged('page', this.queryParams.page)
    Utils.onParamsChanged('perPage', this.queryParams.perPage)
    this.fetchList(false)
  }

  actionShowConfirm(id: number): void {
    this.showModalConfirm = true
    this.cancelID = id
    this.modalActionData = {
      title: `Are you sure you want to cancel the schedule?`,
      description:
        'You will need to create new schedule if you cancel this update app schedule',
      textPrimary: 'Yes, Cancel Schedule',
      textSecondary: 'Check Again',
    }
  }

  doActionModal(): void {
    this.showModalConfirm = false
    controller.cancel(this.cancelID)
  }

  @Watch('controller.successCancel')
  onWatchCancel(isSuccess: boolean): void {
    if (isSuccess) {
      this.showModalSuccess = true
      this.cancelID = -1
    }
  }

  onCloseSuccessModal(): void {
    this.fetchList(false)
  }

  fetchList(isReset: boolean): void {
    if (isReset) {
      Utils.onParamsChanged('page', '1')
      this.queryParams.page = 1
    }

    controller.getAll(this.onQueryParams)
  }
}
